import * as React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { Trans } from "react-i18next"

const ErrorNotFoundPage: React.FC = () => (
	<>
		<Layout>
			<h1>
				<Trans>Sorry... the page could not be found</Trans>
			</h1>
			<p>
				<Trans>Maybe you find the answer on one of the other pages</Trans>
			</p>
		</Layout>
	</>
);

export default ErrorNotFoundPage;

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: {language: {eq: $language}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
